/**
 * @description - The all services that are related to the documents' data
 */

// ================================================================================================================== //
// ===================================================== MODULES ==================================================== //
// ================================================================================================================== //

// Firestore
import {
  getDocs,
  getDoc,
  Timestamp,
  addDoc,
  updateDoc,
  QueryDocumentSnapshot, setDoc, writeBatch, getFirestore, query, where,
} from 'firebase/firestore';
// Queries
import {
  queryAllDocsByProjectUid,
  queryAllDocsByCompanyUid,
  queryDocByUid,
  queryAllDocumentsByDocUid,
  queryDocumentByUid,
  queryDocumentContentsByUid,
  queryMessage,
  getMessagesQuery,
  queryEmail,
  queryNewDoc,
  queryNewDocContent,
  queryNewDocContentReference, queryNewDocRequisitesReference,
} from './queries';
import { fetchTotalNumberByCustomFields } from 'src/services/common';
// Local type
import {
  Doc,
  DocCategory,
  DocumentStatus,
  toDoc,
  Document,
  toDocument,
  DocumentType,
  DocumentContent,
  DocumentRequisite,
  DocumentHeader,
  DocumentFooter,
  toDocumentContent,
} from './models';
import { documentsPaths } from "src/configs";

// ================================================================================================================== //
// ====================================================== LOGIC ===================================================== //
// ================================================================================================================== //

/**
 * @description - The method is triggering the total document number fetching process
 * @param company_uid
 * @param docCategory
 * @param onSuccess
 * @param onFail
 * @param active
 */
export function fetchDocsTotalNumberByCompanyUid(
  company_uid: string,
  docCategory: DocCategory,
  onSuccess: (totalNumber: number) => void,
  onFail: (error: string) => void,
  active = true,
) {
  fetchTotalNumberByCustomFields(
    company_uid,
    [
      [documentsPaths.FIELD_CATEGORY, docCategory],
    ],
    documentsPaths.COLLECTION_DOCS,
    onSuccess,
    onFail,
    active,
  );
}

/**
 * @description - The method is fetching the docs list based on company uid
 * @param user_uid - The user uid that do the fetching
 * @param company_uid - The company uid which docs need to be fetched
 * @param docCategory - The doc category type which need to be fetched
 * @param onSuccess - The on success method which need to be triggered when docs fetched
 * @param onFail - The on fail method which need to be triggered when something went wrong
 * @param documentStatus - The document status, if it needs custom ordering and filtering
 * @param limitNumber - The paginated total number of each page
 * @param nextPage - The paginated data's page number
 * @param orderField - The order field name, if it needs custom ordering
 * @param orderDirection - The order direction
 * @param active - The document activity status
 */
export function fetchAllDocsByCompanyUid(
  user_uid: string,
  company_uid: string,
  docCategory: DocCategory,
  onSuccess: (docs: Doc[]) => void,
  onFail: (error: string) => void,
  documentStatus?: DocumentStatus,
  limitNumber?: number,
  nextPage?: QueryDocumentSnapshot,
  orderField?: string,
  orderDirection?: "asc" | "desc",
  active?: boolean,
) {
  getDocs(queryAllDocsByCompanyUid(
    user_uid,
    company_uid,
    docCategory,
    documentStatus,
    limitNumber,
    nextPage,
    orderField,
    orderDirection,
    active,
  ))
    .then((querySnapshots) => {
      const docs: Doc[] = [];
      let lastSnapshot: QueryDocumentSnapshot;
      querySnapshots.forEach((querySnapshot) => {
        const docData = querySnapshot.data();
        if (docData) {
          docs.push(toDoc(docData));
        }
        lastSnapshot = querySnapshot;
      });
      onSuccess(docs);
    })
    .catch((error) => onFail(error.message))
}

export function fetchAllDocsByProjectUid(
  projectUid: string,
  onSuccess: (docs: Doc[]) => void,
  onFail: (error: string) => void,
) {
  getDocs(queryAllDocsByProjectUid(
    '',
    'rdovUxTkloT2QAHL2q8CqRsKJ552',
    projectUid,
    'template'
  ))
    .then((querySnapshots) => {
      const docs: Doc[] = [];
      let lastSnapshot: QueryDocumentSnapshot;
      querySnapshots.forEach((querySnapshot) => {
        const docData = querySnapshot.data();
        if (docData) {
          docs.push(toDoc(docData));
        }
        lastSnapshot = querySnapshot;
      });
      onSuccess(docs);
    })
    .catch((error) => onFail(error.message))
}

export function fetchDocByDocUid(
  docUid: string,
  onSuccess: (doc: Doc) => void,
  onFail: (error: string) => void,
) {
  const documentReference = queryDocByUid(docUid);

  getDoc(documentReference)
    .then((snapshot) => {
      if (snapshot.exists()) {
        onSuccess(toDoc(snapshot.data()));
      } else {
        onFail("Can't find the document");
      }
    })
    .catch(onFail)
}

export function generateTerminationByDocUid(
  docUid: string,
  data: Record<string, any>,
  onSuccess: () => void,
  onFail: (error: string) => void,
  documentUid: string
) {
  const allQueries = queryDocumentContentsByUid(docUid, documentUid, 'rdovUxTkloT2QAHL2q8CqRsKJ552');
  Promise.all([
    getDocs(query(allQueries.contents, where('order', 'in', [2]))).catch((error) => { console.log(error); return null; }),
    getDocs(query(allQueries.requisites, where('order', '==', 1))).catch((error) => { console.log(error); return null; }),
  ]).then(([contentsSnapshots, requisitesSnapshots]) => {
    const currentContents: DocumentContent[] = [];
    const currentRequisites: DocumentRequisite[] = [];
    contentsSnapshots?.forEach((snapshot) => {
      currentContents.push(toDocumentContent(snapshot.data()));
    });
    requisitesSnapshots?.forEach((snapshot) => {
      currentRequisites.push(toDocumentContent(snapshot.data()));
    });

    const currentContent = currentContents[0];
    const currentRequisite = currentRequisites[0];

    const batch = writeBatch(getFirestore());
    const docReference = queryNewDoc();
    const newTerminationDoc = {
      ...data,
      display_name: `${data.display_name}_Termination`,
      description: `${data.description}_Termination`,
      status: 'draft',
      signed_by: null,
      customer_signature: null,
      signed_date_by_customer: null,
      confirm_date: null,
      created: Timestamp.now(),
      updated: Timestamp.now(),
      uid: docReference.id,
    };

    batch.set(docReference, newTerminationDoc);

    const documentReference = queryNewDocContent(docReference.id);

    batch.set(documentReference, {
      active: true,
      company_uid: 'rdovUxTkloT2QAHL2q8CqRsKJ552',
      created: Timestamp.now(),
      updated: Timestamp.now(),
      created_by_uid: 'None',
      updated_by_uid: 'None',
      display_name: 'Termination',
      description: 'Termination',
      doc_uid: docReference.id,
      order: 1,
      status: 'active',
      type: 'contract',
      uid: documentReference.id,
    });

    const contents = [
      {
        'type': 'title',
        'content': {
          'hy': [
            ['UWC ԿԱՐՃԱԺԱՄԿԵՏ ԿՐԹԱԿԱՆ ԾՐԱԳՐԻ ՄԱՍՆԱԿՑՈՒԹՅԱՆ # '],
            [data.display_name, true],
            ['ՊԱՅՄԱՆԱԳԻՐԸ ԼՈՒԾԵԼՈՒ ՄԱՍԻՆ ՀԱՄԱՁԱՅՆԱԳԻՐ']
          ],
          'ru': [
            ['СОГЛАШЕНИЕ О РАСТОРЖЕНИИ ДОГОВОРА УЧАСТИЯ В КРАТКОСРОЧНОЙ ОБРАЗОВАТЕЛЬНОЙ  ПРОГРАММЕ UWC # '],
            [data.display_name, true]
          ],
        },
      },
      {
        'content': {
          'hy': [['ԴԻԼԻՋԱՆԻ ՄԻՋԱԶԳԱՅԻՆ ԴՊՐՈՑ, ՀԱՅԱՍՏԱՆ ՀԻՄՆԱԴՐԱՄ, ոչ առևտրային կազմակերպություն, ոչ պետական ուսումնական հաստատություն, հիմնադրված և գործող համաձայն Հայաստանի Հանրապետության օրենսդրության (գրանցման համար՝ 222.160.32098, ՀՎՀՀ՝ 00108599, գտնվելու վայր՝ Գետափնյա փ. շ. 7, Դիլիջան, 3903, Տավուշ, Հայաստան)` ի դեմս լիազորված անձ Տիգրան Բաբայանի, ով գործում է 27.11.2023թ․ տրված լիազորագրի հիման վրա (այսուհետ՝ «Ծրագրի կազմակերպիչ»), մի կողմից և']],
          'ru': [['Фонд ДИЛИЖАНСКАЯ МЕЖДУНАРОДНАЯ ШКОЛА, АРМЕНИЯ, некоммерческая организация и негосударственное образовательное учреждение, созданное и действующее по законодательству Республики Армения (номер государственной регистрации: 222.160.32098, ИНН: 00108599, зарегистрированное по адрес: Республика Армения, марз Тавуш, Дилижан, Гетапня 7) в лице уполномоченного представителя Тиграна Бабаяна, действующего на основании доверенности от 31.05.2023 (далее- «Организатор Программы»), с одной стороны']],
        },
      },
      {
        'content': {
          'hy': currentContent?.values?.hy?.map((valueData) => [valueData.value, true]) ?? [['_____ FULL INFO _____', true]],
          'ru': currentContent?.values?.ru?.map((valueData) => [valueData.value, true]) ?? [['_____ FULL INFO _____', true]],
        },
      },
      {
        'content': {
          'hy': [['Այսուհետ միասին անվանվելով «Կողմեր», իսկ առանձին՝ «Կողմ»,']],
          'ru': [['Далее совместно именуемые «Стороны», а каждый по отдельности – «Сторона»']],
        },
      },
      {
        'content': {
          'hy': [
            ['Հաշվի առնելով, որ Պատվիրատուն տեղեկացրել է Ծրագրի կազմակերպչին այն մասին, որ իրենց երեխան (այսուհետ՝ «Մասնակից») չի կարողանա մասնակցել'],
            ['UWC կարճաժամկետ', true],
            ['կրթական ծրագրին, ինչի նպատակով Կողմերը կնքել էին մասնակցության պայմանագիր N'],
            [data.display_name, true],
            ['(այսուհետ՝ «Պայմանագիր»),']],
          'ru': [
            ['Принимая во внимание, что Заказчик проинформировал о том, что ребенок (далее именуемый «Участник») не сможет участвовать в'],
            ['краткосрочной образовательной программе UWC', true],
            ['для участия в котором Стороны подписали Договор краткосрочной образовательной программы UWC N'],
            [data.display_name, true],
            ['(далее именуемого «Договор»), Стороны приняли решение заключить настоящее соглашение (далее - Соглашение) о расторжении Договора.']],
        },
      },
      {
        'content': {
          'hy': [['Կողմերը համաձայն են համարել Պայմանագիրը լուծված սույն Համաձայնագիրը ստորագրելու օրը:']],
          'ru': [['Стороны соглашаются считать Договор расторгнутым в день подписания настоящего Соглашения.']],
        },
        'point': '1'
      },
      {
        'content': {
          'hy': [['Կողմերը հաստատում են, որ սույն Համաձայնագիրը կնքելու պահին որևէ առարկություն կամ նյութական և/կամ այլ պահանջ միմյանց նկատմամբ կապված Պայմանագրի լուծման հետ չունեն:']],
          'ru': [['Стороны подтверждают, что на момент подписания настоящего Соглашения не имеют друг к другу никаких материальных и/или иных претензий, связанных с расторжением Договора.']],
        },
        'point': '2',
      },
      {
        'content': {
          'hy': [['Սույն Համաձայնագիրը կնքված է հայերեն և ռուսերեն լեզուներով: Հակասություններ դեպքում հայերեն տարբերակը ունի գերիշխող ուժ:']],
          'ru': [['Настоящее Соглашение составлено на армянском и русском языках. В случае противоречий, текст на армянском имеет преимущественную силу.']],
        },
        'point': '3',
      },
      {
        'content': {
          'hy': [['Սույն Համաձայնագիրը համարվում է կնքված առցանց հարթակի (ինտերնետ կայքի) միջոցով: ՀՀ «Պետական սահմանի մասին» օրենքի և ՀՀ «Արժութային կարգավորման և արժութային վերահսկողության մասին» օրենքի իմաստով ինտերնետ միջավայրը չի համարվում ՀՀ տարածք:']],
          'ru': [['Настоящее Соглашение считается заключенным через онлайн-платформа (сайт). По смыслу Закона РА «О государственной границе», Закона РА «О валютном регулировании и валютном контроле» интернет-среда не считается территорией РА. Для целей статьи 450 Гражданского кодекса РА договор будет считаться надлежащим образом заключенным в письменной форме.']],
        },
        'point': '4',
      },
      {
        'content': {
          'hy': [['Սույն Համաձայնագիրը համարվում է կնքված Կողմերից վերջինի կողմից հաստատելու օրը:']],
          'ru': [['Настоящее Соглашение считается заключенным в день утверждения Соглашения последней из Сторон.']],
        },
        'point': '5',
      },
    ];

    console.log(currentRequisite);

    const requisites = [
      {
        'type': 'subtitle1',
        'content': {
          'hy': [['Պատվիրատու']],
          'ru': [['Заказчик']],
        },
      },
      {
        'content': {
          'hy': [[currentRequisite?.values?.hy?.[0]?.value ?? '___FULL_NAME___', true]],
          'ru': [[currentRequisite?.values?.ru?.[0]?.value ?? currentRequisite?.values?.ru?.[0]?.placeholder ?? '___FULL_NAME___', true]],
        },
      },
      {
        'type': 'subtitle1',
        'content': {
          'hy': [['Ծրագրի կազմակերպիչ']],
          'ru': [['Организатор Программы']],
        },
      },
      {
        'content': {
          'hy': [['Դիլիջանի միջազգային դպրոց, Հայաստան Հիմնադրամ']],
          'ru': [['Фонд ДИЛИЖАНСКАЯ МЕЖДУНАРОДНАЯ ШКОЛА, АРМЕНИЯ']],
        },
      },
      {
        'content': {
          'hy': [['Հասցե՝ Գետափնյա փ. շ. 7, Դիլիջան, 3903, Տավուշ, Հայաստան']],
          'ru': [['Адрес: Республика Армения, регион Тавуш, Дилижан, ул. Гетапня 7']],
        },
      },
      {
        'type': 'subtitle1',
        'content': {
          'hy': [['Լիազորված անձ՝ Տիգրան Բաբայան']],
          'ru': [['Уполномоченное лицо: Тигран Бабаян']],
        },
      },
    ]

    requisites.map((requisite, index) => {
      const requisitesReference = queryNewDocRequisitesReference(docReference.id, documentReference.id);
      batch.set(requisitesReference, {
        active: true,
        category: requisite.type ?? 'paragraph',
        company_uid: 'rdovUxTkloT2QAHL2q8CqRsKJ552',
        created_by_uid: 'None',
        updated_by_uid: 'None',
        created: Timestamp.now(),
        updated: Timestamp.now(),
        document_uid: documentReference.id,
        format: 'justify',
        order: index,
        point: null,
        style: 'normal',
        uid: requisitesReference.id,
        values: {
          hy: requisite.content.hy.map((valueData) => ({
            editable: valueData?.length > 1,
            placeholder: valueData[0],
            value: valueData[0],
            style: 'normal',
          })),
          ru: requisite.content.ru.map((valueData) => ({
            editable: valueData?.length > 1,
            placeholder: valueData[0],
            value: valueData[0],
            style: 'normal',
          })),
        }
      })
    });

    contents.map((content, index) => {
      const contentsReference = queryNewDocContentReference(docReference.id, documentReference.id);
      batch.set(contentsReference, {
        active: true,
        category: content.type ?? 'paragraph',
        company_uid: 'rdovUxTkloT2QAHL2q8CqRsKJ552',
        created_by_uid: 'None',
        updated_by_uid: 'None',
        created: Timestamp.now(),
        updated: Timestamp.now(),
        document_uid: documentReference.id,
        format: 'justify',
        order: index,
        point: content.point ?? null,
        style: 'normal',
        uid: contentsReference.id,
        values: {
          hy: content.content.hy.map((valueData) => ({
            editable: valueData?.length > 1,
            placeholder: valueData[0],
            value: valueData[0],
            style: 'normal',
          })),
          ru: content.content.ru.map((valueData) => ({
            editable: valueData?.length > 1,
            placeholder: valueData[0],
            value: valueData[0],
            style: 'normal',
          })),
        }
      })
    });
    batch.commit().then(() => {window.location.href = `https://uwc.bisflow.io/app/active/contracts/${docReference.id}/document/${documentReference.id}/view`;}).catch(onFail);

  }).catch(onFail);
}

export function sendMessage(docUid: string, userUid: string, message: string) {
  const reference = queryMessage(docUid);
  setDoc(reference, {
    uid: reference.id,
    message,
    userUid: userUid,
    updated: Timestamp.now(),
    created: Timestamp.now(),
    docUid: docUid,
  }).then(() => window.location.reload()).catch(console.log)
}

export function saveNewContracts(contractData: Record<string, any>, contract_content: Record<string, any> | null, onSuccess: VoidFunction) {
  const reference = queryNewDoc();
  const finalContractData = {
    ...contractData,
    uid: reference.id,
    created: Timestamp.now(),
    updated: Timestamp.now(),
  }

  const batch = writeBatch(getFirestore());
  batch.set(reference, finalContractData);
  const newContentReference = queryNewDocContent(reference.id);
  const docData = {
    active: true,
    company_uid: 'rdovUxTkloT2QAHL2q8CqRsKJ552',
    created_by_uid: contractData.created_by_uid,
    updated_by_uid: contractData.updated_by_uid,
    description: '',
    display_name: contractData.display_name,
    doc_uid: reference.id,
    uid: newContentReference.id,
    order: 0,
    status: 'active',
    type: 'contract',
    created: Timestamp.now(),
    updated: Timestamp.now(),
  };
  batch.set(newContentReference, docData);
  contract_content?.contents?.map((content: Record<string, any>) => {
    const contentReference = queryNewDocContentReference(reference.id, newContentReference.id);
    const finalContent = {
      ...content,
      uid: contentReference.id,
      created: Timestamp.now(),
      updated: Timestamp.now(),
    };
    batch.set(contentReference, finalContent);
  });
  contract_content?.requisites?.map((content: Record<string, any>) => {
    const contentReference = queryNewDocRequisitesReference(reference.id, newContentReference.id);
    const finalContent = {
      ...content,
      uid: contentReference.id,
      created: Timestamp.now(),
      updated: Timestamp.now(),
    };
    batch.set(contentReference, finalContent);
  });
  batch.commit().then(() => window.location.reload()).catch((error) => console.log(error?.message ?? error));
  // const reference = queryMessage(docUid);
  // setDoc(reference, {
  //   uid: reference.id,
  //   message,
  //   userUid: userUid,
  //   updated: Timestamp.now(),
  //   created: Timestamp.now(),
  //   docUid: docUid,
  // }).then(() => window.location.reload()).catch(console.log)
}

export function getMessages(docUid: string, onSuccess: (data: any[]) => void) {
  const reference = getMessagesQuery(docUid);
  getDocs(reference).then((querySnapshots) => {
    const allDocuments: any[] = [];
    let lastSnapshot: QueryDocumentSnapshot;
    querySnapshots.forEach((querySnapshot) => {
      const docData = querySnapshot.data();
      if (docData) {
        allDocuments.push(docData);
      }
      lastSnapshot = querySnapshot;
    });
    onSuccess(allDocuments);
  })
    .catch((error) => console.log(error.message));
}

export function sendEmail(subject: string, content: string, email: string) {
  const reference = queryEmail('');
  setDoc(reference, {
    uid: reference.id,
    updated: Timestamp.now(),
    created: Timestamp.now(),
    to: [email],
    message: {
      subject: `JDI Contract is ready: ${subject}`,
      html: content,
    }
  }).then(() => window.location.reload()).catch(console.log)
}

export function sendEmailNoReload(subject: string, content: string, email: string) {
  const reference = queryEmail('');
  setDoc(reference, {
    uid: reference.id,
    updated: Timestamp.now(),
    created: Timestamp.now(),
    to: [email],
    message: {
      subject: `JDI Contract is ready: ${subject}`,
      html: content,
    }
  }).catch(console.log)
}

export function updateDocByDocUid(
  docUid: string,
  data: Partial<Doc>,
  onSuccess: () => void,
  onFail: (error: string) => void,
) {
  const documentReference = queryDocByUid(docUid);

  updateDoc(documentReference, { ...data, updated: Timestamp.now() })
    .then(onSuccess)
    .catch(onFail);
}

export function fetchAllDocumentsByDocUid(
  docUid: string,
  onSuccess: (documents: Document[]) => void,
  onFail: (error: string) => void,
  docCategory?: DocCategory,
  documentStatus?: DocumentStatus,
  limitNumber?: number,
  nextPage?: QueryDocumentSnapshot,
  orderField?: string,
  orderDirection?: "asc" | "desc",
  active?: boolean,
  documentType?: DocumentType,
) {
  getDocs(queryAllDocumentsByDocUid(docUid, documentStatus, limitNumber, nextPage, active, documentType))
    .then((querySnapshots) => {
      const allDocuments: Document[] = [];
      let lastSnapshot: QueryDocumentSnapshot;
      querySnapshots.forEach((querySnapshot) => {
        const docData = querySnapshot.data();
        if (docData) {
          allDocuments.push(toDocument(docData));
        }
        lastSnapshot = querySnapshot;
      });
      onSuccess(allDocuments);
    })
    .catch((error) => onFail(error.message));
}

export function fetchDocumentByDocumentUid(
  companyUid: string,
  docUid: string,
  documentUid: string,
  // ToDo fix any
  onSuccess: (documentData: {
      document: Document,
      contents: DocumentContent[],
      requisites: DocumentRequisite[],
      headers: DocumentHeader[],
      footers: DocumentFooter[],
    }) => void,
  onFail: (error: string) => void,
  limitNumber?: number,
  nextPage?: QueryDocumentSnapshot,
  orderField?: string,
  orderDirection?: "asc" | "desc",
  documentStatus?: DocumentStatus,
  docCategory?: DocCategory,
  documentType?: DocumentType,
  active?: boolean,
) {
  // ToDo add filtering
  const allQueries = queryDocumentContentsByUid(docUid, documentUid, companyUid);
  const documentQuery = queryDocumentByUid(docUid, documentUid);
  Promise
    .all([
      getDoc(documentQuery),
      getDocs(allQueries.contents).catch((error) => { console.log(error); return null; }),
      getDocs(allQueries.requisites).catch((error) => { console.log(error); return null; }),
      getDocs(allQueries.headers).catch((error) => { console.log(error); return null; }),
      getDocs(allQueries.footers).catch((error) => { console.log(error); return null; }),
    ])
    .then(([
      documentSnapshot,
      contentsSnapshots,
      requisitesSnapshots,
      headersSnapshots,
      footersSnapshots,
    ]) => {
      if (documentSnapshot.exists()) {
        const document = toDocument(documentSnapshot.data());
        const contents: DocumentContent[] = [];
        const requisites: DocumentRequisite[] = [];
        const headers: DocumentHeader[] = [];
        const footers: DocumentFooter[] = [];
        contentsSnapshots?.forEach((snapshot) => {
          contents.push(toDocumentContent(snapshot.data()));
        });
        requisitesSnapshots?.forEach((snapshot) => {
          requisites.push(toDocumentContent(snapshot.data()));
        });
        headersSnapshots?.forEach((snapshot) => {
          headers.push(toDocumentContent(snapshot.data()));
        });
        footersSnapshots?.forEach((snapshot) => {
          footers.push(toDocumentContent(snapshot.data()));
        });
        onSuccess({
          document,
          contents,
          requisites,
          headers,
          footers,
        })
      } else {
        onFail("Can't fetch the document")
      }
    })
    .catch(onFail);
}
